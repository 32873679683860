import { Charge, SearchParams, SearchResult } from "../../entities"
import { ChargeRepository } from "../../repositories"
import UserStorage from "../../util/userStorage"

export class ChargeService {
  public static async search(params: SearchParams): Promise<SearchResult<Charge>> {
    const token = UserStorage.getToken()
    const result = await ChargeRepository.search(params, token)
    return result
  }

  public static async csv(params: SearchParams): Promise<string> {
    const token = UserStorage.getToken()
    const result = await ChargeRepository.csv(params, token)
    return result
  }


  public static async get(id: number): Promise<Charge> {
    const token = UserStorage.getToken()
    return await ChargeRepository.get(id, token)
  }

  public static async create(charge: Charge): Promise<Charge> {
    const token = UserStorage.getToken()
    return await ChargeRepository.create(charge, token)
  }

  public static async update(charge: Charge): Promise<Charge> {
    const token = UserStorage.getToken()
    return await ChargeRepository.update(charge, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await ChargeRepository.delete(id, token)
  }

  public static async getByUser(userId: number): Promise<SearchResult<Charge>>{
    const token = UserStorage.getToken()
    return await ChargeRepository.getByUser(userId, token)
  }
}
