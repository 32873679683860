import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"

import DataTable from "../../../../components/dataTable/DataTable"

import {
  Button,
  Flex,
  FormControl,
  Input,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react"

import {
  User,
  SearchParams,
  SearchResultInfo,
  SearchFilter,
} from "../../../../entities"
import { UserService } from "../../../../services"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import { GrView } from "react-icons/gr"
import Loader from "../../../../components/Loader/Loader"
import { BsPersonCircle, BsTrash3 } from "react-icons/bs"

export default function UserListPage() {
  const [users, setUsers] = useState<User[]>([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const load = async (page = 1) => {
    setLoading(true)

    const filters: SearchFilter[] = []
    if (name) {
      filters.push(
        new SearchFilter({ key: "name", value: name, operator: "like" })
      )
    }

    if (email) {
      filters.push(
        new SearchFilter({ key: "email", value: email, operator: "eq" })
      )
    }

    filters.push(new SearchFilter({ key: "roleCode", value: "notCustomer" }))

    const params = new SearchParams(filters, page, 10)
    UserService.search(params)
      .then((result) => {
        setUsers(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const remove = (id: number) => {
    const confirm = window.confirm("Deseja realmente apagar este usuário?")
    if (!confirm) return

    setLoading(true)
    UserService.delete(id)
      .then((success) => load(resultInfo.page))
      .finally(() => setLoading(false))
  }

  const renderActions = (params: any) => {
    return (
      <div>
        <ChakraLink as={ReactRouterLink} to={`/user/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
        <Button
          bg="#E7ECFF"
          color="#00335B"
          transition="0.2s"
          _hover={{ bg: "#114B8F", color: "#fff" }}
          onClick={() => remove(params.id)}
        >
          <BsTrash3 />
        </Button>
      </div>
    )
  }

  const columns = [
    { field: "id", headerName: "ID", mWidth: "20%" },
    { field: "email", headerName: "E-mail", mWidth: "75%" },
    { field: "name", headerName: "Nome", mWidth: "80%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "15%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Usuários", link: "/user" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/vehicleTransfer/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsPersonCircle />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Usuários
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          maxW={{ base: "none", sm: "none", md: "537px", lg: "537px" }}
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "none",
            lg: "none",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW="422px"
            isInvalid={false}
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-end"
            gap="0.5rem"
          >
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="name"
              placeholder="Nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button
            minW="95px"
            size="md"
            borderRadius="0.375rem"
            bg="#00335B"
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            _hover={{ bg: "#00335B" }}
            onClick={() => load(1)}
          >
            Filtrar
          </Button>
          <ChakraLink as={ReactRouterLink} to="/user/0">
            <Button
              minW="95px"
              size="md"
              borderRadius="0.375rem"
              bg="#00335B"
              fontFamily="Raleway"
              fontSize="0.875rem"
              fontWeight="600"
              color="#fff"
              _hover={{ bg: "#00335B" }}
            >
              Adicionar
            </Button>
          </ChakraLink>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={users}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
