import React from "react"
import { Box, Spinner } from "@chakra-ui/react"

export default function Loader() {
  return (
    <Box
      minW="100vw"
      minH="100vh"
      maxH="100vh"
      opacity="0.7"
      zIndex="999"
      position="fixed"
      top="0"
      left="0"
      bg="rgb(240, 240, 240)"
      overflow="hidden"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Box>
  )
}
