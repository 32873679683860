import { VehicleTransferDocument, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class VehicleTransferDocumentRepository {
  public static async search(params: SearchParams, token: string): Promise<SearchResult<VehicleTransferDocument>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/vehicleTransferDocument?page=${params.page}&limit=${params.limit}`, {}, headers)

    const result = new SearchResult<VehicleTransferDocument>(response.data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<VehicleTransferDocument> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/vehicleTransferDocument/${id}`, {}, headers)

    return new VehicleTransferDocument(response)
  }

  public static async create(vehicleTransferDocument: VehicleTransferDocument, token: string): Promise<VehicleTransferDocument> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('POST', '/vehicleTransferDocument', vehicleTransferDocument, headers)

    return new VehicleTransferDocument(response)
  }

  public static async update(vehicleTransferDocument: VehicleTransferDocument, token: string): Promise<VehicleTransferDocument> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('PUT', `/vehicleTransferDocument/$[object Object].id}`, vehicleTransferDocument, headers)

    return new VehicleTransferDocument(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` };

    const response = await client.DoRequest('DELETE', `/vehicleTransferDocument/${id}`, {}, headers)

    return response === true
  }
}