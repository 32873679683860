import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import Layout from "../../../../components/layout/Layout"
import DataTable from "../../../../components/dataTable/DataTable"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Link as ChakraLink,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { GrView } from "react-icons/gr"
import InputMask from "react-input-mask"
import {
  User,
  SearchParams,
  SearchResultInfo,
  SearchFilter,
  Vehicle,
} from "../../../../entities"
import { UserService } from "../../../../services"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import ShowVehiclesModal from "../../../../components/showVehiclesModal/showVehiclesModal"
import Loader from "../../../../components/Loader/Loader"
import { BsPersonFill } from "react-icons/bs"

export default function CustomerListPage() {
  const showVehiclesDisclosure = useDisclosure()

  const [users, setUsers] = useState<User[]>([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [cpf, setCpf] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [licensePlate, setLicensePlate] = useState("")
  const [id, setId] = useState("")
  const [vehicles, setVehicles] = useState<Vehicle[]>([])

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const load = async (page = 1) => {
    setLoading(true)

    const filters: SearchFilter[] = []
    if (email) {
      filters.push(
        new SearchFilter({ key: "email", value: email, operator: "like" })
      )
    }

    if (name) {
      filters.push(
        new SearchFilter({ key: "name", value: name, operator: "eq" })
      )
    }

    if (cpf) {
      filters.push(new SearchFilter({ key: "cpf", value: cpf, operator: "eq" }))
    }

    if (cnpj) {
      filters.push(
        new SearchFilter({ key: "cnpj", value: cnpj, operator: "eq" })
      )
    }

    if (id) {
      filters.push(new SearchFilter({ key: "id", value: id, operator: "eq" }))
    }

    if (licensePlate) {
      filters.push(
        new SearchFilter({
          key: "licensePlate",
          value: licensePlate,
          operator: "eq",
        })
      )
    }

    filters.push(new SearchFilter({ key: "roleCode", value: "customer" }))

    const params = new SearchParams(filters, page, 10)

    UserService.search(params)
      .then((result) => {
        console.log("customers", result.data)
        setUsers(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const renderActions = (params: any) => {
    return (
      <div>
        <ChakraLink as={ReactRouterLink} to={`/customer/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const renderLicencePlate = (params: any) => {
    if (params.vehicles.length > 0) {
      if (params.vehicles.length === 1) {
        return params.vehicles[0]?.licensePlate
      } else {
        return (
          <div>
            <ChakraLink
              w="full"
              as={ReactRouterLink}
              onClick={() => {
                setVehicles(params.vehicles)
                showVehiclesDisclosure.onOpen()
              }}
            >
              <Text>
                {params.vehicles[0]?.licensePlate}
                {" + " + (params.vehicles.length - 1) + " placas"}
              </Text>
            </ChakraLink>
          </div>
        )
      }
    } else {
      return " - "
    }
  }

  const columns = [
    { field: "id", headerName: "ID", mWidth: "15%" },
    { field: "email", headerName: "E-mail", mWidth: "80%" },
    { field: "firstname", headerName: "Nome", mWidth: "45%" },
    { field: "lastname", headerName: "Sobrenome", mWidth: "45%" },
    { renderCell: renderLicencePlate, headerName: "Placa", mWidth: "30%" },
    {
      field: "bankAccount.balanceFormated",
      headerName: "Saldo",
      mWidth: "80%",
    },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "15%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Clientes", link: "/customer" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/vehicleTransfer/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsPersonFill />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Clientes
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          maxW={{ base: "none", sm: "none", md: "537px", lg: "537px" }}
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "none",
            lg: "none",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW="422px"
            isInvalid={false}
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-end"
            gap="0.5rem"
          >
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="E-mail"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="Nome"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="CNPJ"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="cnpj"
              value={cnpj}
              as={InputMask}
              mask="99.999.999/9999-99"
              maskChar={null}
              onChange={(event) => setCnpj(event.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="CPF"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="cpf"
              value={cpf}
              as={InputMask}
              mask="999.999.999-99"
              maskChar={null}
              onChange={(event) => setCpf(event.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "98px", md: "98px", lg: "98px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="ID"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="number"
              name="id"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "154px", md: "154px", lg: "154px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="Placa"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="licensePlate"
              textTransform="capitalize"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value.toUpperCase())}
            />
          </FormControl>
          <Button
            minW="95px"
            size="md"
            borderRadius="0.375rem"
            bg="#00335B"
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            _hover={{ bg: "#00335B" }}
            onClick={() => load(1)}
          >
            Buscar
          </Button>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={users}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
      <ShowVehiclesModal
        vehicles={vehicles}
        isOpen={showVehiclesDisclosure.isOpen}
        onOpen={showVehiclesDisclosure.onOpen}
        onClose={showVehiclesDisclosure.onClose}
      />
    </Layout>
  )
}
