export class DashboardPayment {
  public type: string
  public today: number
  public d1: number
  public d2: number
  public d3: number
  public d4: number
  public d5: number
  public d6: number
  public d15: number
  public month: number
  public m1: number
  public m2: number

  public constructor(data: any = {}) {
    this.type = data.type || ""
    this.today = data.today || ""
    this.d1 = data.d1 || 0
    this.d2 = data.d2 || 0
    this.d3 = data.d3 || 0
    this.d4 = data.d4 || 0
    this.d5 = data.d5 || 0
    this.d6 = data.d6 || 0
    this.d15 = data.d15 || 0
    this.month = data.month || 0
    this.m1 = data.m1 || 0
    this.m2 = data.m2 || 0
  }
}
