import { User } from "../user";
import { Transaction } from "./Transaction";

export class BankAccount {
  private _id: string;
  public id: number;
  public user: User | undefined;
  public transactions: Transaction[] = [];
  public balance: number;
  public balanceFormated: string;

  public constructor(data: any) {
    this._id = data.id || data._id;

    this.id = data.id;

    if (data.user) {
      this.user = new User(data.user);
    }

    if (data.transactions) {
      for (const transaction of data.transactions) {
        this.transactions.push(new Transaction(transaction));
      }
    }

    this.balance = data.balance;

    this.balanceFormated = data.balanceFormated || "";
  }
}
