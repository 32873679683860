import React from "react"
import { Flex, Image, Text } from "@chakra-ui/react"

export default function Footer() {
  return (
    <Flex
      w="100%"
      px="1.23rem"
      mt="1.58rem"
      mb="1.25rem"
      justifyContent="space-between"
      alignItems="center"
      fontFamily="DM Sans"
      color="#A2ACBD"
      fontSize="0.875rem"
      fontWeight="500"
      textAlign={{
        base: "center",
        sm: "inherit",
        md: "inherit",
        lg: "inherit",
      }}
      direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
      gap={{ base: "0.5rem", sm: "0", md: "0", lg: "0" }}
    >
      <Text>© 2024 - Todos os direitos reservados.</Text>
      <Flex alignItems="center" gap="0.25rem">
        <Text fontWeight="400">Made by</Text>
        <Image
          maxH="1rem"
          src="/images/IconAttri.png"
          alt="Ícone logotipo da Attri"
        />
      </Flex>
    </Flex>
  )
}
