import { Charge, SearchParams, SearchResult } from "../../entities"
import ApiClient from "../clients/ApiClient"
import QueryStringBuilder from "../../util/queryBuilder"

const client = new ApiClient()

export class ChargeRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<Charge>> {
    const headers = { Authorization: `Bearer ${token}` }

    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/charge?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<Charge>(response.data, response.info)

    return result
  }

  public static async csv(params: SearchParams, token: string): Promise<string> {
    const headers = { Authorization: `Bearer ${token}` }

    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    queryStringBuilder.addParameter('csv', '1')
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/charge?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers,
      true
    )
    
    return URL.createObjectURL(response)
    
  }

  public static async getByUser(userId: number, token: string): Promise<SearchResult<Charge>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/charge/byUser/${userId}`, {}, headers)

    return new SearchResult<Charge>(response.data, response.info)
  }

  public static async get(id: number, token: string): Promise<Charge> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/charge/${id}`, {}, headers)

    return new Charge(response)
  }

  public static async create(charge: Charge, token: string): Promise<Charge> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest("POST", "/charge", charge, headers)

    return new Charge(response)
  }

  public static async update(charge: Charge, token: string): Promise<Charge> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "PUT",
      `/charge/${charge.id}`,
      charge,
      headers
    )

    return new Charge(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "DELETE",
      `/charge/${id}`,
      {},
      headers
    )

    // const response = await client.DoRequest(
    //   "DELETE",
    //   `/charge/${id}`,
    //   {},
    //   headers
    // );

    return response === true
  }
}
