import React from "react"
import { Flex } from "@chakra-ui/react"
import SidebarMenuItem from "./SidebarMenuItem"

interface SidebarMenuProps {
  menuItems: any
}

export default function SidebarMenu(props: SidebarMenuProps) {
  return (
    <Flex w="100%" direction="column">
      {props.menuItems.map((item: any, idx: number) => (
        <SidebarMenuItem key={`sidebar-menu-item-${idx}`} {...item} />
      ))}
    </Flex>
  )
}
