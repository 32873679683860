import React from "react"
import Layout from "../../../components/layout/Layout"
import {
  Button,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Box,
} from "@chakra-ui/react"
import Breadcrumbs from "../../../components/layout/breadcrumbs/Breadcrumbs"
import { BsArrowLeftShort, BsCopy, BsInfoCircleFill } from "react-icons/bs"
import "./ChargesDescriptionPage.scss"

export default function ChargesDescriptionPage() {
  return (
    <Layout>
      <Flex w="100%" flexDirection="column" gap="1rem">
        <Flex bg="#fff" borderRadius="1rem" p="1rem" direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Cobranças", link: "/charge" },
              { label: "Cobrança", link: "/charge-description" },
            ]}
          />
          <Text
            fontSize="2.25rem"
            fontWeight="700"
            color="#114B8F"
            fontFamily="DM Sans"
            display="flex"
            gap="1rem"
            alignItems="center"
            flexWrap="wrap"
          >
            <Button
              fontSize="2rem"
              bg="#E7ECFF"
              color="#00335B"
              _hover={{ bg: "#E7ECFF" }}
            >
              <BsArrowLeftShort />
            </Button>
            Descrição de cobranças
          </Text>
        </Flex>

        <Flex w="100%" direction="column" gap="1rem">
          <UnorderedList
            className="charge-description-list"
            w="100%"
            m="0"
            py="2rem"
            borderTop="1px solid #E2E8F0"
            borderBottom="1px solid #E2E8F0"
            listStyleType="none"
            fontFamily="DM Sans"
            display="flex"
            flexWrap="wrap"
            gap="1rem"
          >
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "14%", md: "14%", lg: "14%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
              >
                NOME
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  Ricardo
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
              >
                PLACA
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  IYT2113
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                ID da Transação
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  121570
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                ID da Transação Financeira
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  482445
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Data Disponível
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  15/01/2024 11:10:48
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Data de Ocorrência
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  15/01/2024 11:10:29
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <Box w="100%" my="1rem" h="1px" bg="#E2E8F0"></Box>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "14%", md: "14%", lg: "14%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                TAG
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  -
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Classe de Tax. do Veículo
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  1
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Tarifa
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 8,30
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Desconto
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 0,42
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                DUF
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 0,00
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                DBT
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 0,42
                </Text>
              </Flex>
            </ListItem>
            <Box w="100%" my="1rem" h="1px" bg="#E2E8F0"></Box>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "14%", md: "14%", lg: "14%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Acréscimo
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 0,00
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Multa
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 0,00
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Juros
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  R$ 0,00
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Código da Praça
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  21
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Descrição da Praça
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  ERS122-Km108-Sul
                </Text>
                <Button bg="none" _hover={{ bg: "none" }} border="none">
                  <BsCopy />
                </Button>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Código da Faixa
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  03
                </Text>
              </Flex>
            </ListItem>
            <Box w="100%" my="1rem" h="1px" bg="#E2E8F0"></Box>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "14%", md: "14%", lg: "14%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Status
              </Text>
              <Flex w="100%" alignItems="center" gap="0.25rem">
                <Box color="#FFB800">
                  <BsInfoCircleFill />
                </Box>
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  Aberto
                </Text>
              </Flex>
            </ListItem>
            <ListItem
              pl={{ base: "1rem", sm: "0.5rem", md: "0.5rem", lg: "0.5rem" }}
              pr={{ base: "1rem", sm: "1.5rem", md: "1.5rem", lg: "1.5rem" }}
              borderRight={{ base: "none" }}
              minW={{ base: "100%", sm: "16.1%", md: "16.1%", lg: "16.1%" }}
              minH="57px"
              display="flex"
              flexDirection="column"
              gap="0.05rem"
              textTransform="uppercase"
            >
              <Text
                as="span"
                fontWeight="500"
                color="#8E99AB"
                fontSize="0.75rem"
                textTransform="uppercase"
              >
                Foi ressincronizado?
              </Text>
              <Flex w="100%" alignItems="center">
                <Text
                  as="span"
                  fontWeight="700"
                  color="#2D3748"
                  fontSize="1rem"
                >
                  Não
                </Text>
              </Flex>
            </ListItem>
          </UnorderedList>
          <Flex w="100%" mt="1rem" flexWrap="wrap" gap="1rem">
            <Button
              fontFamily="Raleway"
              fontWeight="600"
              fontSize="0.875rem"
              color="#1A202C"
              p="0.25rem 0.5rem"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              _hover={{ bg: "#F7F8F9" }}
            >
              Receber pagamento em dinheiro
            </Button>
            <Button
              fontFamily="Raleway"
              fontWeight="600"
              fontSize="0.875rem"
              color="#1A202C"
              p="0.25rem 0.5rem"
              borderRadius="0.375rem"
              bg="#F7F8F9"
              _hover={{ bg: "#F7F8F9" }}
            >
              Receber pagamento em vale
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}
