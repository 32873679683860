import React, { useEffect, useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import Layout from "../../../../components/layout/Layout"
import DataTable from "../../../../components/dataTable/DataTable"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Link as ChakraLink,
  Text,
} from "@chakra-ui/react"
import { GrView } from "react-icons/gr"
import InputMask from "react-input-mask"
import {
  VehicleTransfer,
  SearchParams,
  SearchResultInfo,
  SearchFilter,
} from "../../../../entities"
import { VehicleTransferService } from "../../../../services"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import Formatter from "../../../../util/formatter"
import Loader from "../../../../components/Loader/Loader"
import { BsCarFrontFill } from "react-icons/bs"

export default function VehicleTransferListPage() {
  const [vehicleTransfers, setVehicleTransfers] = useState<VehicleTransfer[]>(
    []
  )
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)

  const [licensePlate, setLicensePlate] = useState("")
  const [cpf, setCpf] = useState("")
  const [cnpj, setCnpj] = useState("")
  const [status, setStatus] = useState("")

  useEffect(() => {
    load()
    //eslint-disable-next-line
  }, [])

  const load = async (page = 1) => {
    setLoading(true)

    const filters: SearchFilter[] = []
    if (cpf) {
      filters.push(new SearchFilter({ key: "cpf", value: cpf, operator: "eq" }))
    }

    if (cnpj) {
      filters.push(
        new SearchFilter({ key: "cnpj", value: cnpj, operator: "eq" })
      )
    }

    if (licensePlate) {
      filters.push(
        new SearchFilter({
          key: "licensePlate",
          value: licensePlate,
          operator: "eq",
        })
      )
    }

    if (status) {
      filters.push(
        new SearchFilter({ key: "status", value: status, operator: "eq" })
      )
    }

    const params = new SearchParams(filters, page, 10)
    VehicleTransferService.search(params)
      .then((result) => {
        setVehicleTransfers(result.data)
        setResultInfo(result.info)
      })
      .finally(() => setLoading(false))
  }

  const renderActions = (params: any) => {
    return (
      <div>
        <ChakraLink as={ReactRouterLink} to={`/vehicle/transfer/${params.id}`}>
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const renderStatus = (params: any) => {
    return Formatter.formatStatus(params.status)
  }

  const renderCreatedAt = (params: any) => {
    return Formatter.formatDateTime(new Date(params.createdAt))
  }

  const columns = [
    { field: "vehicle.licensePlate", headerName: "Placa", mWidth: "60%" },
    { renderCell: renderStatus, headerName: "Status", mWidth: "35%" },
    { renderCell: renderCreatedAt, headerName: "Criado", mWidth: "80%" },
    {
      headerName: "Ações",
      renderCell: renderActions,
      mWidth: "15%",
      mAlign: "end",
      mDisplay: "none",
    },
  ]

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Transferência de Veículos", link: "/vehicle/transfer" },
            ]}
            additionalHeaderInfo={<></>}
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCarFrontFill />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Transferência de Veículos
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          maxW={{ base: "none", sm: "none", md: "537px", lg: "537px" }}
          justifyContent={{
            base: "flex-end",
            sm: "flex-end",
            md: "none",
            lg: "none",
          }}
          gap="1.25rem"
          alignItems="flex-end"
          direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        >
          <FormControl
            w="100%"
            maxW="422px"
            isInvalid={false}
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-end"
            gap="0.5rem"
          >
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="licensePlate"
              placeholder="Placa"
              value={licensePlate}
              onChange={(e) => setLicensePlate(e.target.value)}
            />
            <Input
              maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="document"
              placeholder="Documento"
              value={cpf}
              as={InputMask}
              mask="999.999.999-99"
              maskChar={null}
              onChange={(event) => setCpf(event.target.value)}
            />
            <Select
              maxW={{ base: "100%", sm: "120px", md: "120px", lg: "120px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              _placeholder={{ color: "#A2ACBD" }}
              placeholder="Status"
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="">Todos</option>
              <option value="open">Aberto</option>
              <option value="approved">Aprovado</option>
              <option value="declined">Reprovado</option>
            </Select>
            <Input
              maxW={{ base: "100%", sm: "98px", md: "98px", lg: "98px" }}
              size="sm"
              fontFamily="Raleway"
              fontSize="0.75rem"
              fontWeight="400"
              placeholder="ID"
              _placeholder={{ color: "#A2ACBD" }}
              border="1px solid #E2E8F0"
              borderRadius="0.25rem"
              type="text"
              name="document"
              value={cnpj}
              as={InputMask}
              mask="99.999.999/9999-99"
              maskChar={null}
              onChange={(event) => setCnpj(event.target.value)}
            />
          </FormControl>
          <Button
            minW="95px"
            size="md"
            borderRadius="0.375rem"
            bg="#00335B"
            fontFamily="Raleway"
            fontSize="0.875rem"
            fontWeight="600"
            color="#fff"
            _hover={{ bg: "#00335B" }}
            onClick={() => load(1)}
          >
            Filtrar
          </Button>
        </Flex>
      </Flex>
      <DataTable
        checkboxSelection={false}
        rows={vehicleTransfers}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
