export interface ValidationError extends Error {
    path?: string
}

export class ValidationErrorHandling {
    public static handle (errors: any): ValidationError[] {
        const validationErrors: ValidationError[] = []
        if (Array.isArray(errors)) {
            for (const error of errors) {
                if (error.name !== 'ValidationError') continue

                validationErrors.push(error)
            }
        }
        return validationErrors
    }

    public static hasError(path: string, errors: ValidationError[]): boolean {
        return errors.findIndex(error => error.path === path) >= 0
    }

    public static getErrorMessage(path: string, errors: ValidationError[]): string|null {
        let message = ''
        for (const error of errors) {
            if (path === error.path) {
                message += error.message + '. '
            }
        }

        if (message) return message
        return null
    }

    public static remove(path: string, errors: ValidationError[]): ValidationError[] {
        return errors.filter(item => item.path !== path)
    }
}