import { Dashboard } from "../../entities/csg/Dashboard"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class DashboardRepository {
  public static async get(token: string): Promise<Dashboard> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/dashboard?`, {}, headers)

    return new Dashboard(response)
  }
}
