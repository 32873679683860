import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import InputMask from "react-input-mask"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  SimpleGrid,
  Select,
  Button,
  ButtonGroup,
  useToast,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import { Role, SearchParams, User } from "../../../../entities"
import { RoleService, UserService } from "../../../../services"

import theme from "../../../../config/Themes"
import Loader from "../../../../components/Loader/Loader"
import { ValidationInput } from "../../../../util"

export default function UserFormPage() {
  const params = useParams()
  const toast = useToast()
  const navigate = useNavigate()

  const [form, setForm] = useState(new User({ type: "PF" }))
  const [roles, setRoles] = useState<Role[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadRoles()

    const id = params.id ? parseInt(params.id) : 0
    if (id) {
      load(id)
    }

    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)

    UserService.get(id)
      .then((user: User) => {
        setForm(user)
      })
      .finally(() => setLoading(false))
  }

  const loadRoles = () => {
    RoleService.search(new SearchParams()).then((result) => {
      const roles: Role[] = []
      for (const role of result.data) {
        if (role.code === "customer") continue

        if (role.code === "admin") role.name += " (Todas as funções)"
        if (role.code === "financial")
          role.name += " (Cobranças, Clientes e Resgate de Saldo)"
        if (role.code === "support") role.name += " (Transferência de Veículos)"
        if (role.code === "operational") role.name += " (Cobranças e Clientes)"
        roles.push(role)
      }
      setRoles(roles)
    })
  }

  const save = () => {
    const user = new User(form)
    user.roleId = user.role.id

    if (user.firstname.length <= 2) {
      toast({
        title: "Digite o primeiro nome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.lastname.length <= 2) {
      toast({
        title: "Digite o sobrenome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (!user.role?.id) {
      toast({
        title: "Escolha um perfil.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (!ValidationInput.isValidCPF(user.document)) {
      toast({
        title: "O CPF informado é inválido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.phone.length < 15) {
      toast({
        title: "Digite o Telefone",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (!user.id) {
      if (user.password.length < 6) {
        toast({
          title: "Digite a senha.",
          status: "error",
          duration: 2000,
        })
        return
      }
    }

    if (user.password !== user.passwordConfirm) {
      toast({
        title: "A senha e a confirmação devem ser iguais.",
        status: "error",
        duration: 2000,
      })
      return
    }
    user.isActive = true

    setLoading(true)
    if (user.id) {
      UserService.update(user)
        .then((result) => navigate("/user"))
        .catch((error) => {
          toast({
            title: error?.response.data?.message,
            status: "error",
            duration: 2000,
          })
        })
        .finally(() => setLoading(false))
    } else {
      UserService.create(user)
        .then((result) => navigate("/user"))
        .catch((error) => {
          toast({
            title: error?.response.data?.message,
            status: "error",
            duration: 2000,
          })
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Layout>
      {loading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Usuários", link: "/user" },
          { label: "Visualizar", link: `/user/${form.id}` },
        ]}
        additionalHeaderInfo={
          <>
            <Link to="/user">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </Link>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Usuário
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Nome</FormLabel>
            <Input
              type="text"
              name="firstname"
              value={form.firstname}
              onChange={(event) =>
                setForm({ ...form, firstname: event.target.value })
              }
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Sobrenome</FormLabel>
            <Input
              type="text"
              name="lastname"
              value={form.lastname}
              onChange={(event) =>
                setForm({ ...form, lastname: event.target.value })
              }
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>E-mail</FormLabel>
            <Input
              type="text"
              name="email"
              value={form.email}
              onChange={(event) =>
                setForm({ ...form, email: event.target.value })
              }
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Perfil</FormLabel>
            <Select
              placeholder="Perfil"
              value={form.role.id}
              onChange={(event) => {
                const role = roles.find(
                  (role) => role.id === parseInt(event.target.value)
                )
                if (role) {
                  setForm({ ...form, role })
                }
              }}
            >
              {roles.map((role: Role) => (
                <option value={role.id} key={role.id}>
                  {role.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>
              {form.type === "PF" ? "CPF" : "CNPJ"}
            </FormLabel>
            <Input
              type="text"
              name="document"
              value={form.document}
              as={InputMask}
              mask={
                form.type === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
              }
              maskChar={null}
              onChange={(event) =>
                setForm({ ...form, document: event.target.value })
              }
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Telefone</FormLabel>
            <Input
              as={InputMask}
              mask="(99) 99999-9999"
              maskChar={null}
              type="text"
              name="phone"
              value={form.phone}
              onChange={(event) =>
                setForm({ ...form, phone: event.target.value })
              }
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Senha</FormLabel>
            <Input
              type="password"
              name="password"
              value={form.password}
              onChange={(event) =>
                setForm({ ...form, password: event.target.value })
              }
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>
              Confirmação de Senha
            </FormLabel>
            <Input
              type="password"
              name="passwordConfirm"
              value={form.passwordConfirm}
              onChange={(event) =>
                setForm({ ...form, passwordConfirm: event.target.value })
              }
            />
          </FormControl>
        </SimpleGrid>
        <ButtonGroup>
          <Button
            fontSize="1rem"
            bg="#00335B"
            color="#fff"
            _hover={{ bg: "#003f70", transition: "0.3s" }}
            onClick={save}
          >
            Salvar
          </Button>
        </ButtonGroup>
      </Flex>
    </Layout>
  )
}
