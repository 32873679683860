const theme = {
  color: {
    primary: process.env.REACT_APP_THEME_COLOR_PRIMARY || "#41729F",
    secondary: process.env.REACT_APP_THEME_COLOR_SECONDARY || "#5885AF",
    tertiary: process.env.REACT_APP_THEME_COLOR_TERTIARY || "#274472",
    quaternary: process.env.REACT_APP_THEME_COLOR_QUATERNARY || "#C3E0E5",
    quinary: process.env.REACT_APP_THEME_COLOR_QUINARY || "#ffffff",
  },
  logo: {
    url: process.env.REACT_APP_THEME_LOGO_URL || "https://freeflow.csg.com.br/images/csg-logo-1.png",
  },
  banner: {
    //url: process.env.REACT_APP_THEME_LOGO_URL || "/public/images/",
  },
  company: {
    name: process.env.REACT_APP_THEME_COMPANY_NAME || "CSG",
  },
  copyright: {
    text: process.env.REACT_APP_THEME_COPYRIGHT_TEXT || `© ${(new Date().getFullYear())} - Todos os direitos reservados.`,
  }
}

export default theme