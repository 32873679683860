import menuItems from "./MenuItems"

const config = {
  logo: 'https://www.entelco.com.br/admin/view/image/logo.png',
  primaryColor: '',
  secondaryColor: '',
  companyName: 'CSG',
  menuItems: menuItems
}

export default config