import React from "react"
import { Flex, Select, Text } from "@chakra-ui/react"

export interface PaginationProps {
  page: number
  pages: number
  pageSize: number
  count: number
  onPageChange?: (page: number) => void
}

export default function DataTablePagination(props: PaginationProps) {
  const changePage = (newPage: number) => {
    console.log("teste")
    if (props?.onPageChange) props.onPageChange(newPage)
  }

  return (
    <Flex
      id="pagination"
      w="100%"
      my="1rem"
      alignItems="center"
      gap="0.5rem"
      flexWrap="wrap"
    >
      <Select
        fontFamily="DM Sans"
        fontSize="1rem"
        fontWeight="700"
        borderRadius="0.25rem"
        color="#fff"
        bg="#00335B"
        w="100px"
        onChange={(event) => changePage(parseInt(event.target.value))}
        css={{
          "&::-webkit-scrollbar": {
            height: "8px",
            borderRadius: "0.375rem",
            background: "#E2E8F0",
          },
          "&::-webkit-scrollbar-track": {
            height: "8px",
            borderRadius: "0.375rem",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "0.375rem",
            background: "#00335B",
          },
        }}
      >
        {Array.from({ length: props.pages }, (_, i) => i + 1).map((i) => (
          // <option ></option>
          <Text
            as="option"
            fontFamily="DM Sans"
            color="#2D3748"
            fontSize="1rem"
            fontWeight="700"
            cursor="pointer"
            value={i}
            key={`pagination-${i}`}
          >
            {i}
          </Text>
        ))}
      </Select>
      {/* <IconButton
        aria-label="Search database"
        icon={<BiFirstPage />}
        isDisabled={props.page === 1}
        onClick={() => changePage(1)}
      />
      <IconButton
        aria-label="Search database"
        icon={<MdNavigateBefore />}
        isDisabled={props.page === 1}
        onClick={() => changePage(props.page - 1)}
      />
      {Array.from({ length: props.pages }, (_, i) => i + 1).map((i) => (
        <Button
          key={`pagination-button-${i}`}
          border={
            props.page === i
              ? "1px solid rgb(200, 200, 200)"
              : "1px solid rgb(200, 200, 200)"
          }
          bg={props.page === i ? "rgb(235, 235, 235)" : "none"}
          color={props.page === i ? "rgb(80, 80, 80)" : "rgb(100, 100, 100)"}
          padding="0.5rem"
          fontSize="0.85rem"
          onClick={() => changePage(i)}
          _hover={{
            border: "1px solid rgb(220, 220, 220)",
            bg: "rgb(245, 245, 245)",
            color: "rgb(100, 100, 100)",
          }}
        >
          {i}
        </Button>
      ))}
      <IconButton
        aria-label="Search database"
        icon={<MdNavigateNext />}
        isDisabled={props.page === props.pages}
        onClick={() => changePage(props.page + 1)}
      />
      <IconButton
        aria-label="Search database"
        icon={<BiLastPage />}
        isDisabled={props.page === props.pages}
        onClick={() => changePage(props.pages)}
      /> */}
    </Flex>
  )
}
