import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"

import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  SimpleGrid,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import theme from "../../../../config/Themes"
import { BalanceReturn } from "../../../../entities/csg/BalanceReturn"
import { BalanceReturnService } from "../../../../services/csg/BalanceReturnService"
import Loader from "../../../../components/Loader/Loader"

export default function BalanceReturnFormPage() {
  const params = useParams()

  const [form, setForm] = useState(new BalanceReturn({}))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    if (id) {
      load(id)
    }
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)
    BalanceReturnService.get(id)
      .then((balanceReturn: BalanceReturn) => {
        setForm(balanceReturn)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Layout>
      {loading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Solicitações de Resgate", link: "/balance-return" },
          {
            label: "Solicitação de Resgate",
            link: `/balance-return/${form.id}`,
          },
        ]}
        additionalHeaderInfo={
          <>
            <Link to="/balance-return">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </Link>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Solicitação de Resgate
        </Text>

        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 4, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Nome
            </FormLabel>
            <Input type="text" name="name" value={form.fullName} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              E-mail
            </FormLabel>
            <Input type="text" name="email" value={form.email} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Banco
            </FormLabel>
            <Input type="text" name="account" value={form.bank} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Agência
            </FormLabel>
            <Input type="text" name="branch" value={form.branch} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Conta
            </FormLabel>
            <Input type="text" name="branch" value={form.account} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Valor
            </FormLabel>
            <Input type="text" name="account" value={form.amount} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Taxa
            </FormLabel>
            <Input type="text" name="account" value={form.tax} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Documento
            </FormLabel>
            <Input type="text" name="account" value={form.document} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Status
            </FormLabel>
            <Input type="text" name="account" value={form.status} readOnly />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data do pedido
            </FormLabel>
            <Input
              type="text"
              name="datetimeOccurrence"
              value={form.createdAt?.toString() || ""}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data de Atualização
            </FormLabel>
            <Input
              type="text"
              name="datetimeOccurrence"
              value={form.updatedAt?.toString() || ""}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Data da Aprovação
            </FormLabel>
            <Input
              type="text"
              name="datetimeOccurrence"
              value={form.approvedAt?.toString() || ""}
              readOnly
            />
          </FormControl>

          <FormControl isRequired isInvalid={false}>
            <FormLabel minHeight="3rem" color={theme.color.tertiary}>
              Aprovador por
            </FormLabel>
            <Input
              type="text"
              name="name"
              value={form.approvedBy?.toString() ?? ""}
              readOnly
            />
          </FormControl>
        </SimpleGrid>
      </Flex>
    </Layout>
  )
}
