import { BalanceReturn } from "./BalanceReturn"

export class BalanceReturnBulk {
  public id: number
  public name: string
  public status: string
  public total: number
  public balanceReturns: BalanceReturn[] = []
  public createdAt: Date
  public updatedAt: Date
  public approvedAt: Date

  constructor(data: any = {}) {
    this.id = data.id || 0
    this.name = data.name || ""
    this.status = data.status || ""
    this.total = data.total || 0

    if (data.balanceReturns) {
      for (const balanceReturn of data.balanceReturns) {
        this.balanceReturns.push(balanceReturn)
      }
    }

    this.createdAt = new Date(data.createdAt) || new Date()
    this.updatedAt = data.updatedAt
    this.approvedAt = data.approvedAt
  }
}
