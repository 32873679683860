import { User } from "../user"
import { Order } from "./Order"

export class Charge {
  public id: number
  public user: User | undefined
  public transactionId: number
  public financialTransactionId: number
  public datetimeAvailable: Date
  public datetimeOccurrence: Date
  public licensePlate: string
  public tagIdentifier: string
  public vehicleClassRated: string
  public rateAmount: number
  public discount: number
  public discountDuf: number
  public discountDbt: number
  public fine: number
  public interest: number
  public fee: number
  public plazaCode: string
  public plazaDescription: string
  public laneCode: string
  public status: string
  public isReSynchorization: boolean
  public createdAt: Date
  public updatedAt: Date

  public datetimeAvailableFormated: string
  public datetimeOccurrenceFormated: string
  public dueDateFormated: string
  public rateAmountFormated: string
  public discountFormated: string
  public discountDbtFormated: string
  public discountDufFormated: string
  public fineFormated: string
  public interestFormated: string
  public feeFormated: string
  public statusFormated: string
  public createdAtFormated: string

  public order?: Order

  public constructor(data: any) {
    this.id = data.id

    if (data.user) {
      this.user = new User(data.user)
    }

    this.transactionId = data.transactionId

    this.financialTransactionId = data.financialTransactionId

    this.datetimeAvailable = data.datetimeAvailable

    this.datetimeOccurrence = data.datetimeOccurrence

    this.licensePlate = data.licensePlate

    this.tagIdentifier = data.tagIdentifier

    this.vehicleClassRated = data.vehicleClassRated

    this.rateAmount = data.rateAmount || 0
    this.discount = data.discount || 0
    this.discountDuf = data.discountDuf || 0
    this.discountDbt = data.discountDbt || 0
    this.fine = data.fine || 0
    this.interest = data.interest || 0
    this.fee = data.fee || 0

    this.plazaCode = data.plazaCode

    this.plazaDescription = data.plazaDescription

    this.laneCode = data.laneCode

    this.status = data.status

    this.isReSynchorization = data.isReSynchorization === true

    this.createdAt = data.createdAt

    this.updatedAt = data.updatedAt

    if (data.order) {
      this.order = new Order(data.order)
    }

    this.datetimeAvailableFormated = data.datetimeAvailableFormated || ""
    this.datetimeOccurrenceFormated = data.datetimeOccurrenceFormated || ""
    this.dueDateFormated = data.dueDateFormated || ""
    this.rateAmountFormated = data.rateAmountFormated || ""
    this.discountFormated = data.discountFormated || ""
    this.discountDbtFormated = data.discountDbtFormated || ""
    this.discountDufFormated = data.discountDufFormated || ""
    this.fineFormated = data.fineFormated || ""
    this.interestFormated = data.interestFormated || ""
    this.feeFormated = data.feeFormated || ""
    this.statusFormated = data.statusFormated || ""
    this.createdAtFormated = data.createdAtFormated || ""
  }
}
