import { BankAccount } from "../../entities"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class BankAccountRepository {
  public static async getByUser(userId: number, token: string): Promise<BankAccount> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/bankAccount/getByUser/${userId}`, {}, headers)

    return new BankAccount(response)
  }
}