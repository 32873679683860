import { CheckoutInput, Order, SearchParams, SearchResult } from "../../entities"
import { OrderRepository } from "../../repositories"
import UserStorage from "../../util/userStorage"

export class OrderService {
  public static async search(params: SearchParams): Promise<SearchResult<Order>> {
    const token = UserStorage.getToken()
    const result = await OrderRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<Order> {
    const token = UserStorage.getToken()
    return await OrderRepository.get(id, token)
  }

  public static async create(order: Order): Promise<Order> {
    const token = UserStorage.getToken()
    return await OrderRepository.create(order, token)
  }

  public static async update(order: Order): Promise<Order> {
    const token = UserStorage.getToken()
    return await OrderRepository.update(order, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await OrderRepository.delete(id, token)
  }

  public static async checkout(input: CheckoutInput): Promise<Order> {
    const token = UserStorage.getToken()
    return await OrderRepository.checkout(input, token)
  }
}