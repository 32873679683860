import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Charge } from "../../../../entities"
import { ChargeService } from "../../../../services"
import Loader from "../../../../components/Loader/Loader"
import Formatter from "../../../../util/formatter"

export default function ChargeReceiptPage() {
  const params = useParams()

  const [charge, setCharge] = useState(new Charge({}))
  const [loading, setLoading] = useState(false)

  const [total, setTotal] = useState("")

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    if (id) {
      load(id)
    }
  }, [params.id])

  const load = async (id: number) => {
    setLoading(true)
    ChargeService.get(id)
      .then((charge: Charge) => {
        setCharge(charge)

        const total = charge.rateAmount - charge.discount + charge.fee
        setTotal(Formatter.formatCurrency(total))
      })
      .finally(() => setLoading(false))
  }
  return (
    <>
      {loading && <Loader />}
      <h2>Caminhos da Serra Gaúcha S.A</h2>
      <p>CNPJ: 47.815.827/0001-17</p>
      <br />
      <p>Data de Pagamento: {charge.order?.paidAtFormated}</p>
      <p>Forma de Pagamento: {charge.order?.paymentMethodFormated}</p>
      <p>Valor Total: {total}</p>
      <p>Placa: {charge.licensePlate}</p>
      <br />
      ----------------------------------------
      <br />
      Detalhado de Passagens
      <br />
      Pórtico:
      {charge.plazaDescription}
      <br />
      Data:
      {charge.datetimeOccurrenceFormated}
      <br />
      Cat.Cobrada:
      {charge.vehicleClassRated}
      <br />
      Desconto:{charge.discountFormated}
      <br />
      Juros:{charge.feeFormated}
      <br />
      Valor:{charge.rateAmountFormated}
      <br />
      ----------------------------------------
      <br />
      <br />
      Para emissão de seu recibo de pedágio DFE, <br />
      acesse https://cis.csg.com.br/
    </>
  )
}
