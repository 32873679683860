import React, { useState } from "react"
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Link as LinkChakra,
} from "@chakra-ui/react"

import theme from "../../config/Themes"

export default function RecoveryPasswordPage() {
  const [currentPassword, setCurrentPassword] = useState("")
  const handleCurrentPasswordChange = (event: any) =>
    setCurrentPassword(event.target.value)
  const isCurrentPasswordError = currentPassword === ""

  const [newPassword, setNewPassword] = useState("")
  const handleNewPasswordChange = (event: any) =>
    setNewPassword(event.target.value)
  const isNewPasswordError = newPassword === ""

  const [newPasswordAgain, setNewPasswordAgain] = useState("")
  const handleNewPasswordAgainChange = (event: any) =>
    setNewPasswordAgain(event.target.value)
  const isNewPasswordAgainError = newPasswordAgain === ""

  return (
    <Flex w="100%" minH="100vh" direction="column" justifyContent="center">
      <Flex p="1rem" direction="column" alignItems="center">
        <Flex
          w="100%"
          maxWidth="768px"
          mb="1rem"
          direction="column"
          gap="1.5rem"
        >
          <FormControl isRequired isInvalid={isCurrentPasswordError}>
            <FormLabel>Senha atual</FormLabel>
            <Input
              size="lg"
              type="password"
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
              placeholder="Sua senha atual"
            />
            {isCurrentPasswordError && (
              <FormErrorMessage>Insira a senha atual</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isNewPasswordError}>
            <FormLabel>Nova senha</FormLabel>
            <Input
              size="lg"
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              placeholder="Digite sua nova senha"
            />
            {isNewPasswordError && (
              <FormErrorMessage>Insira a nova senha.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl isRequired isInvalid={isNewPasswordAgainError}>
            <FormLabel>Nova senha (novamente)</FormLabel>
            <Input
              size="lg"
              type="password"
              value={newPasswordAgain}
              onChange={handleNewPasswordAgainChange}
              placeholder="Digite sua nova senha (novamente)"
            />
            {isNewPasswordAgainError && (
              <FormErrorMessage>
                Insira a nova senha novamente.
              </FormErrorMessage>
            )}
          </FormControl>
          <Button
            size="lg"
            bg={theme.color.primary}
            color={theme.color.quinary}
            transition="ease-in-out 0.2s"
            _hover={{ bg: `${theme.color.secondary}` }}
          >
            Recuperar senha
          </Button>
        </Flex>
        <Flex direction="column">
          <Text fontSize="0.875rem" color={theme.color.tertiary}>
            Deseja fazer login?
            <LinkChakra
              href="/login"
              color={theme.color.primary}
              fontWeight="700"
              ml="1"
            >
              Faça seu login
            </LinkChakra>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
