import axios, { AxiosRequestConfig } from "axios";

class ApiClient {
  public async DoRequest(
    method = "GET",
    endpoint: string,
    data = {},
    aditionalHeaders: any = {},
    blob=false
  ) {
    const baseUri = this.getBaseUri();
    const url = `${baseUri}${endpoint}`;
    console.log("URL da solicitação:", url);

    const headers: any = {};
    headers["Content-Type"] = "application/json";
    headers.Accept = "application/json";

    for (const idx in aditionalHeaders) {
      headers[idx] = aditionalHeaders[idx];
    }

    const requestConfig: AxiosRequestConfig = {
      method,
      headers,
      url,
      data,
    };

    if (blob) {
      requestConfig.responseType = 'blob'
    }

    try {
      const response = await axios(requestConfig);
      if (response.data && response.data.status === "error") {
        throw new Error("Legacy API status error");
      }
      return response.data;
    } catch (error) {
      console.error("Erro Axios:", error);
      throw error;
    }
  }
  public getBaseUri(): string {
    return process.env.REACT_APP_API_URI ?? "";
  }

  public getRestDefaultUri(): string {
    return this.getBaseUri();
  }
}

export default ApiClient;
