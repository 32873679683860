import { VehicleTransfer, SearchParams, SearchResult } from "../../entities"
import { VehicleTransferRepository } from "../../repositories"
import UserStorage from "../../util/userStorage"

export class VehicleTransferService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<VehicleTransfer>> {
    const token = UserStorage.getToken()
    const result = await VehicleTransferRepository.search(params, token)
    return result
  }

  public static async get(id: number): Promise<VehicleTransfer> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.get(id, token)
  }

  public static async create(
    vehicleTransfer: VehicleTransfer
  ): Promise<VehicleTransfer> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.create(vehicleTransfer, token)
  }

  public static async update(
    vehicleTransfer: VehicleTransfer
  ): Promise<VehicleTransfer> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.update(vehicleTransfer, token)
  }

  public static async delete(id: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.delete(id, token)
  }

  public static async updateStatus(id: number, status: string): Promise<boolean> {
    const token = UserStorage.getToken()
    return await VehicleTransferRepository.updateStatus(id, status, token)
  }
}
