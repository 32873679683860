import { SearchFilter } from "./SearchFilter"

export class SearchParams {
  public filter: SearchFilter[] = []
  public page = 1
  public limit = 20

  public constructor(filter: SearchFilter[] = [], page = 1, limit = 20) {
    this.filter = filter
    this.page = page
    this.limit = limit
  }
}
