import ApiClient from "../clients/ApiClient";

const apiClient = new ApiClient()

export class AuthRepository {
  public static async login(email: string, password: string): Promise<string> {
    try {
      const data = { email, password }
      const res = await apiClient.DoRequest('POST', '/auth/login', data)
      console.log(res)
      return res.token
    } catch (error: any) {
      console.log('error', error)
      console.log('status', error?.response?.status)
      if (error?.response?.status === 401) {
        throw new Error('Usuário e senha não conferem.')
      }
      if (error?.response?.data?.error) {
        throw new Error(error.response.data.error)
      }
      throw new Error('Erro inesperado.')
    }
  }
}