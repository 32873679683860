import React, { useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  Select,
  useToast,
} from "@chakra-ui/react"
import { SecurityService } from "../../../../services"

interface Props {
  load(): any
  isOpen?: any
  onOpen?: any
  onClose?: any
}

export default function SecurityFormModal(props: Props) {
  const toast = useToast()

  const [ip, setIp] = useState("")
  const [email, setEmail] = useState("")
  const [type, setType] = useState("")

  const save = () => {
    if (ip === "" && email === "") {
      toast({
        title: "Digite o IP ou E-mail.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (type === "") {
      toast({
        title: "Escolha o tipo.",
        status: "error",
        duration: 2000,
      })
      return
    }

    SecurityService.create(ip, email, type)
      .then((success) => {
        toast({
          title: "Bloqueio/Liberação adicionado.",
          status: "success",
          duration: 2000,
        })

        setIp("")
        setEmail("")
        setType("")

        props.load()
        props.onClose()
      })
      .catch((error) => {
        console.log("error", error?.response?.data?.message)
        if (error?.response?.data?.message) {
          toast({
            title: error?.response?.data?.message,
            status: "error",
            duration: 2000,
            position: "top",
          })
        }
      })
  }

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Adicionar Bloqueio/Liberação</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl
              w="100%"
              maxW="422px"
              isInvalid={false}
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-start"
              gap="0.5rem"
            >
              <Input
                maxW={{ base: "100%", sm: "143px", md: "143px", lg: "143px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="ip"
                placeholder="Informe o IP"
                value={ip}
                onChange={(e) => setIp(e.target.value)}
              />
              <Input
                maxW={{ base: "100%", sm: "143px", md: "143px", lg: "143px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="ip"
                placeholder="Informe o E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Select
                maxW={{ base: "100%", sm: "175px", md: "175px", lg: "175px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Selecione a ação"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="allow">Permitido</option>
                <option value="block">Bloqueado</option>
              </Select>
            </FormControl>
            <Button onClick={save}>Salvar</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
