import { VehicleTransfer, SearchParams, SearchResult } from "../../entities"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class VehicleTransferRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<VehicleTransfer>> {
    const headers = { Authorization: `Bearer ${token}` }

    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const response = await client.DoRequest(
      "GET",
      `/vehicleTransfer?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<VehicleTransfer>(
      response.data,
      response.info
    )

    return result
  }

  public static async get(id: number, token: string): Promise<VehicleTransfer> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/vehicleTransfer/${id}`,
      {},
      headers
    )

    return new VehicleTransfer(response)
  }

  public static async create(
    vehicleTransfer: VehicleTransfer,
    token: string
  ): Promise<VehicleTransfer> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      "/vehicleTransfer",
      vehicleTransfer,
      headers
    )

    return new VehicleTransfer(response)
  }

  public static async update(
    vehicleTransfer: VehicleTransfer,
    token: string
  ): Promise<VehicleTransfer> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "PUT",
      `/vehicleTransfer/$[object Object].id}`,
      vehicleTransfer,
      headers
    )

    return new VehicleTransfer(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "DELETE",
      `/vehicleTransfer/${id}`,
      {},
      headers
    )

    return response === true
  }

  public static async updateStatus(
    id: number,
    status: string,
    token: string
  ): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      `/vehicleTransfer/updateStatus/${id}/${status}`,
      {},
      headers
    )

    return response === true
  }
}
