import { Vehicle } from "./Vehicle"
import { VehicleTransferDocument } from "./VehicleTransferDocument"

export class VehicleTransfer {
  public id: number
  public reason: string
  public explanation: string
  public status: string
  public createdAt: Date
  public updatedAt: Date
  public documents: VehicleTransferDocument[] = []
  public vehicle: Vehicle|null
  public vehicleActived: Vehicle|null

  public constructor(data: any) {
    this.id = data.id
    this.reason = data.reason || ''
    this.explanation = data.explanation || ''
    this.status = data.status || ''
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt

    if (data.documents) {
      for (const document of data.documents) {
        this.documents.push(new VehicleTransferDocument(document ))
      }
    }

    this.vehicle = data.vehicle ? new Vehicle(data.vehicle) : null
    this.vehicleActived = data.vehicleActived ? new Vehicle(data.vehicleActived) : null
  }
}