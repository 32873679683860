import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  SimpleGrid,
  Box,
} from "@chakra-ui/react"
import { Vehicle } from "../../entities"

interface Props {
  vehicles: Vehicle[]
  isOpen?: any
  onOpen?: any
  onClose?: any
  action?: any
}

export default function ShowVehiclesModal(props: Props) {
  const [, setColumns] = useState<number>(0)

  useEffect(() => {
    let calc: number = props.vehicles.length / 15
    setColumns(~~calc)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Placas vinculadas ao usuário</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={4} spacing={2}>
              {props.vehicles.map((vehicle, index) => (
                <Box>
                  <Text>{vehicle.licensePlate}</Text>
                </Box>
              ))}
            </SimpleGrid>
            {/* <Text>{columns}</Text> */}
            {/* <SimpleGrid columns={columns} spacing={2}>
                {props.vehicles.map((vehicle, index) => (
                  <Box>
                    <Text>{vehicle.licensePlate}</Text>
                  </Box>
                ))}
              </SimpleGrid> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
