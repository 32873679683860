import { Vehicle, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class VehicleRepository {
  public static async search(params: SearchParams, token: string): Promise<SearchResult<Vehicle>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/vehicle?page=${params.page}&limit=${params.limit}`, {}, headers)

    const result = new SearchResult<Vehicle>(response.data, response.info)

    return result
  }

  public static async getByUser(userId: number, token: string): Promise<SearchResult<Vehicle>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/vehicle/byUser/${userId}`, {}, headers)

    return new SearchResult<Vehicle>(response.data, response.info)
  }

  public static async get(id: number, token: string): Promise<Vehicle> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/vehicle/${id}`, {}, headers)

    return new Vehicle(response)
  }

  public static async create(vehicle: Vehicle, token: string): Promise<Vehicle> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('POST', '/vehicle', vehicle, headers)

    return new Vehicle(response)
  }

  public static async update(vehicle: Vehicle, token: string): Promise<Vehicle> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('PUT', `/vehicle/$[object Object].id}`, vehicle, headers)

    return new Vehicle(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` };

    const response = await client.DoRequest('DELETE', `/vehicle/${id}`, {}, headers)

    return response === true
  }
}