import { SearchParams, SearchResult, SecurityBlocker } from "../../entities"
import { SecuritRepository } from "../../repositories"
import UserStorage from "../../util/userStorage"

export class SecurityService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<SecurityBlocker>> {
    const token = UserStorage.getToken()
    const result = await SecuritRepository.search(params, token)
    return result
  }

  public static async create(
    ip: string,
    email: string,
    type: string
  ): Promise<boolean> {
    const token = UserStorage.getToken()
    return await SecuritRepository.create(ip, email, type, token)
  }

  public static async changeStatus(
    securityBlocker: SecurityBlocker,
    newStatus: string
  ): Promise<boolean> {
    const token = UserStorage.getToken()
    return await SecuritRepository.changeStatus(
      securityBlocker,
      newStatus,
      token
    )
  }
}
