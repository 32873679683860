import { User } from "../user"
import { Charge } from "./Charge"
import { OrderPix } from "./OrderPix"
import { OrderTotal } from "./OrderTotal"
import { Transaction } from "./Transaction"

export class Order {
  private _id: string
  public id: number
  public user: User | undefined
  public status: string
  public createdAt: Date
  public charges: Charge[] = []
  public transaction: Transaction | undefined

  public pix: OrderPix | undefined

  public totals: OrderTotal | undefined

  public name: string
  public email: string
  public phone: string

  public paidAtFormated: string
  public paymentMethodFormated: string

  public constructor(data: any) {
    this._id = data.id || data._id

    this.id = data.id

    if (data.user) {
      this.user = new User(data.user)
    }

    this.status = data.status

    this.createdAt = data.createdAt

    this.name = data.name || ""
    this.email = data.email || ""
    this.phone = data.phone || ""

    if (data.charges) {
      for (const charge of data.charges) {
        this.charges.push(new Charge(charge))
      }
    }

    if (data.transaction) {
      this.transaction = new Transaction(data.transaction)
    }

    if (data.pix) {
      this.pix = new OrderPix(data.pix)
    }

    if (data.totals) {
      this.totals = new OrderTotal(data.totals)
    }

    this.paidAtFormated = data.paidAtFormated || ""
    this.paymentMethodFormated = data.paymentMethodFormated || ""
  }
}
