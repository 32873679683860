import { User } from "../user";

export class Transaction {
  private _id: string;
  public id: number;
  public user: User | undefined;
  public type: string;
  public code: string;
  public amount: number;
  public status: string;
  public paymentMethod: string;
  public createdAt: Date;
  public updatedAt: Date;

  amountFormated: string;
  statusFormated: string;
  paymentMethodFormated: string;
  createdAtFormated: string;

  public constructor(data: any) {
    this._id = data.id || data._id;

    this.id = data.id;

    if (data.user) {
      this.user = new User(data.user);
    }

    this.type = data.type;

    this.code = data.code;

    this.amount = data.amount;

    this.status = data.status;

    this.paymentMethod = data.paymentMethod;

    this.createdAt = data.createdAt;

    this.updatedAt = data.updatedAt;

    this.amountFormated = data.amountFormated || "";
    this.statusFormated = data.statusFormated || "";
    this.paymentMethodFormated = data.paymentMethodFormated || "";
    this.createdAtFormated = data.createdAtFormated || "";
  }
}
