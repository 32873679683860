export class ValidationInput {
  public static isValidCPF(cpf: any) {
    // Se não for string, o CPF é inválido
    if (typeof cpf !== "string") return false;

    // Remove todos os caracteres que não sejam números
    cpf = cpf.replace(/[^\d]+/g, "");

    // Se o CPF não tem 11 dígitos ou todos os dígitos são repetidos, o CPF é inválido
    if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

    // Transforma de string para number[] com cada dígito sendo um número no array
    cpf = cpf.split("").map((el: any) => +el);

    // Cria uma função interna que calcula o dígito verificador do CPF atual:
    const rest = (count: any) =>
      // Pega os primeiros count dígitos
      ((cpf
        .slice(0, count - 12)
        // e calcula o dígito verificador de acordo com a fórmula da Receita Federal
        .reduce(
          (soma: any, el: any, index: any) => soma + el * (count - index),
          0
        ) *
        10) %
        11) %
      10;

    // O CPF é válido se, e somente se, os dígitos verificadores estão corretos
    return rest(10) === cpf[9] && rest(11) === cpf[10];
  }

  // Regex para validação de string no formato CNPJ
  public static regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/;

  // Método de validação
  // Referência: https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica
  public static isValidCNPJ(value: string) {
    if (!value) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === "string";
    const validTypes =
      isString || Number.isInteger(value) || Array.isArray(value);

    // Elimina valor de tipo inválido
    if (!validTypes) return false;

    // Filtro inicial para entradas do tipo string
    if (isString) {
      // Teste Regex para veificar se é uma string apenas dígitos válida
      const digitsOnly = /^\d{14}$/.test(value);
      // Teste Regex para verificar se é uma string formatada válida
      const validFormat = this.regexCNPJ.test(value);
      // Verifica se o valor passou em ao menos 1 dos testes
      const isValid = digitsOnly || validFormat;

      // Se o formato não é válido, retorna inválido
      if (!isValid) return false;
    }

    // Elimina tudo que não é dígito
    const numbers = this.matchNumbers(value);

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false;

    // Elimina inválidos com todos os dígitos iguais
    const items = Array.from(numbers);
    if (items.length === 1) return false;

    // Separa os 2 últimos dígitos verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = this.validCalc(12, numbers);
    if (digit0 !== digits[0]) return false;

    // Valida 2o. dígito verificador
    const digit1 = this.validCalc(13, numbers);
    return digit1 === digits[1];
  }

  // Método de formatação
  public formatCNPJ(value: string) {
    // Verifica se o valor é válido
    const valid = ValidationInput.isValidCNPJ(value);

    // Se o valor não for válido, retorna vazio
    if (!valid) return "";

    // Elimina tudo que não é dígito
    const numbers = ValidationInput.matchNumbers(value);
    const text = numbers.join("");

    // Formatação do CNPJ: 99.999.999/9999-99
    const format = text.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

    // Retorna o valor formatado
    return format;
  }

  // Cálculo validador
  static validCalc(x: number, numbers: number[]) {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  }

  // Elimina tudo que não é dígito
  static matchNumbers(value: string | number | number[] = "") {
    const match = value.toString().match(/\d/g);
    return Array.isArray(match) ? match.map(Number) : [];
  }
}
