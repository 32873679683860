export class VehicleTransferDocument {
  public id: number
  public name: string
  public path: string
  public createdAt: Date

  public constructor(data: any) {
    this.id = data.id

    this.name = data.name

    this.path = data.path

    this.createdAt = data.createdAt
    
  }
}