import { SearchParams, SearchResult, SecurityBlocker } from "../../entities"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class SecuritRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<SecurityBlocker>> {
    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()

    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest(
      "GET",
      `/security?${queryString}&page=${params.page}&limit=${params.limit}`,
      {},
      headers
    )

    const result = new SearchResult<SecurityBlocker>(
      response.data,
      response.info
    )

    return result
  }

  public static async create(
    ip: string,
    email: string,
    type: string,
    token: string
  ): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      `/security?ip=${ip}&email=${email}&type=${type}`,
      {},
      headers
    )

    return response === true
  }

  public static async changeStatus(
    securityBlocker: SecurityBlocker,
    newStatus: string,
    token: string
  ): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "POST",
      `/security/changeStatus/${securityBlocker.id}/${newStatus}`,
      {},
      headers
    )

    return response === true
  }
}
