import React, { useState } from "react"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Link as ChakraLink,
  Checkbox,
  Image,
  Center,
  Divider,
  Show,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
} from "@chakra-ui/react"
import { AuthService } from "../../services/auth/AuthService"
import { PiPassword } from "react-icons/pi"
import { AiOutlineMail } from "react-icons/ai"

export default function Login() {
  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const handleEmailChange = (event: any) => setEmail(event.target.value)
  const isEmailError = email === ""

  const [password, setPassword] = useState("")
  const handlePasswordChange = (event: any) => setPassword(event.target.value)
  const isPasswordError = password === ""

  const login = async () => {
    AuthService.login(email, password)
      .then(() => {
        navigate("/")
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  return (
    <Flex
      background="#F2F5FF"
      w="100%"
      minH="100vh"
      h="100%"
      direction="column"
    >
      <Flex
        p="0.25rem"
        w="100%"
        justifyContent="center"
        alignItems="center"
        gap="8.2rem"
        justifySelf="center"
        alignSelf="center"
        m="auto"
      >
        <Flex
          w="100%"
          maxW="451px"
          p={{
            base: "1.75rem 1.25rem 3.25rem 1.25rem",
            sm: "1.75rem 2.75rem 3.25rem 2.75rem",
            md: "1.75rem 2.75rem 3.25rem 2.75rem",
            lg: "1.75rem 2.75rem 3.25rem 2.75rem",
          }}
          borderRadius="1rem"
          background="#fff"
          boxShadow="0px 24px 64px 0px rgba(17, 75, 143, 0.22)"
          font-family="Raleway"
          flexDirection="column"
        >
          <Flex
            m="1rem 0 1.75rem 0"
            gap="1.5rem"
            direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
            alignItems={{
              base: "center",
              sm: "normal",
              md: "normal",
              lg: "normal",
            }}
          >
            <Image
              maxW="138px"
              src="/images/csg-logo.png"
              alt="Logotipo da CSG"
            />
            <Show above="480px">
              <Center>
                <Divider orientation="vertical" color="#E2E8F0" bg="#E2E8F0" />
              </Center>
            </Show>
            <Text
              color="#00335B"
              fontSize="1.5rem"
              fontWeight="700"
              lineHeight="1.69rem"
              wordBreak="break-word"
              textAlign={{ base: "center", sm: "unset" }}
            >
              Painel Administrativo
            </Text>
          </Flex>
          <FormControl mt="1rem">
            <FormLabel
              fontSize="0.875rem"
              fontWeight="500"
              lineHeight="1.25rem"
              color="#171923"
            >
              E-mail
            </FormLabel>
            <InputGroup>
              <InputLeftElement h="100%" pointerEvents="none">
                <AiOutlineMail />
              </InputLeftElement>
              <Input
                size="lg"
                type="email"
                placeholder="email@provedor.com"
                _placeholder={{ color: "#A2ACBD" }}
                value={email}
                onChange={handleEmailChange}
              />
              {isEmailError && (
                <FormErrorMessage>E-mail é necessário.</FormErrorMessage>
              )}
            </InputGroup>
          </FormControl>
          <FormControl mt="0.75rem" mb="1rem">
            <FormLabel
              fontSize="0.875rem"
              fontWeight="500"
              lineHeight="1.25rem"
              color="#171923"
            >
              Senha
            </FormLabel>
            <InputGroup>
              <InputLeftElement h="100%" pointerEvents="none">
                <PiPassword />
              </InputLeftElement>
              <Input
                size="lg"
                type="password"
                placeholder="••••••••••••••"
                _placeholder={{ color: "#A2ACBD" }}
                value={password}
                onChange={handlePasswordChange}
              />
              {isPasswordError && (
                <FormErrorMessage>Senha é necessária.</FormErrorMessage>
              )}
            </InputGroup>
          </FormControl>
          <Checkbox
            defaultChecked
            size="md"
            colorScheme="facebook"
            display="flex"
            fontSize="0.875rem"
            fontWeight="400"
            color="#1A202C"
            lineHeight="1.38rem"
          >
            Lembrar minha senha
          </Checkbox>
          <Button
            m="2rem 0 1rem 0"
            w="100%"
            size="lg"
            borderRadius="0.375rem"
            fontSize="1rem"
            fontWeight="700"
            lineHeight="1.5rem"
            bg="#00335B"
            color="#fff"
            _hover={{ bg: "#003f70", transition: "0.3s" }}
            onClick={login}
          >
            Fazer login
          </Button>
          <ChakraLink
            as={ReactRouterLink}
            to="/recovery-password"
            fontSize="0.875rem"
            color="#3531C6"
            fontWeight="700"
            textAlign="center"
            lineHeight="1.20rem"
          >
            Esqueci minha senha
          </ChakraLink>
        </Flex>
        {/* <Show above="lg">
          <Flex direction="column" gap="1.13rem" font-family="Raleway">
            <Flex>
              <Text
                w="100%"
                maxW="545px"
                as="span"
                fontSize={{
                  base: "2.75rem",
                  sm: "3rem",
                  md: "3.25rem",
                  lg: "3.5rem",
                }}
                fontWeight="700"
                color="#00335B"
                lineHeight={{ base: "2.7rem", sm: "3.85rem" }}
                letterSpacing="-1.68px"
              >
                Caminhos que cuidam de
                <Text
                  ml="0.75rem"
                  as="span"
                  fontSize={{
                    base: "2.75rem",
                    sm: "3rem",
                    md: "3.25rem",
                    lg: "3.5rem",
                  }}
                  fontWeight="700"
                  color="#F26522"
                  lineHeight={{ base: "2.7rem", sm: "3.85rem" }}
                  letterSpacing="-1.68px"
                >
                  ti
                </Text>
                .
              </Text>
            </Flex>
            <Text
              w="100%"
              maxW="510px"
              color="#8E99AB"
              fontSize={{
                base: "1.75rem",
                sm: "1.75rem",
                md: "1.88rem",
                lg: "2rem",
              }}
              fontWeight="400"
              lineHeight="2.88rem"
            >
              Facilitamos os pagamentos para que você tenha tempo para quem mais
              importa.
            </Text>
          </Flex>
        </Show> */}
      </Flex>
      <Flex
        mx="3rem"
        mt={{ base: "1rem", sm: "0", md: "0", lg: "0" }}
        justifyContent="space-between"
        alignItems="center"
        fontFamily="DM Sans"
        color="#A2ACBD"
        fontSize="0.875rem"
        fontWeight="500"
        textAlign={{
          base: "center",
          sm: "inherit",
          md: "inherit",
          lg: "inherit",
        }}
        direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
        gap={{ base: "0.5rem", sm: "0", md: "0", lg: "0" }}
      >
        <Text>© 2024 - Todos os direitos reservados.</Text>
        <Flex alignItems="center" gap="0.25rem">
          <Text fontWeight="400">Made by</Text>
          <Image
            maxH="1rem"
            src="/images/IconAttri.png"
            alt="Ícone logotipo da Attri"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
