export class DashboardChargeCount {
  public label: string
  public count: number
  public value: number

  public constructor(data: any = {}) {
    this.label = data.label || ""
    this.count = data.count || 0
    this.value = data.value || 0
  }
}
