import React from "react"
import { Flex } from "@chakra-ui/react"
import SidebarMenu from "../../sidebarmenu/SidebarMenu"

interface SidebarProps {
  collapse: boolean
  setCollapse: any
  menuItems: any
}

export default function Sidebar({
  collapse,
  setCollapse,
  menuItems,
}: SidebarProps) {
  return (
    <Flex w="100%" h="100%">
      <SidebarMenu menuItems={menuItems} />
    </Flex>
  )
}
