import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout/Layout"
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import Breadcrumbs from "../../../components/layout/breadcrumbs/Breadcrumbs"
import { BsFillQuestionCircleFill } from "react-icons/bs"
import UserStorage from "../../../util/userStorage"
import Loader from "../../../components/Loader/Loader"

export default function HelpPage() {
  const [loading, setLoading] = useState(true)
  const roleCode = UserStorage.get()?.role?.code

  useEffect(() => {
    new Promise((r) => setTimeout(r, 6000)).then(() => setLoading(false))
  }, [])

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Ajuda", link: "/help" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/help/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsFillQuestionCircleFill />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Ajuda
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="100%"
          mb="1rem"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={{ base: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}
        >
          <Flex
            w="100%"
            h="full"
            p="2rem"
            display="flex"
            flexDirection="column"
            gap="1rem"
          >
            <Tabs>
              <TabList>
                <Tab>Abrir Chamado</Tab>
                {roleCode === "admin" && <Tab>Atividades</Tab>}
                {roleCode === "admin" && <Tab>Notas</Tab>}
              </TabList>

              <TabPanels>
                <TabPanel>
                  <iframe
                    title="Abrir Chamado"
                    className="clickup-embed clickup-dynamic-height"
                    src="https://forms.clickup.com/9007126848/f/8cdvpa0-27493/8B90YALO08ZR9OCU5X"
                    width="100%"
                    height="700"
                  ></iframe>
                  <script
                    async
                    src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"
                  ></script>
                </TabPanel>
                <TabPanel>
                  <iframe
                    title="Atividades"
                    className="clickup-embed"
                    src="https://sharing.clickup.com/9007126848/l/h/8cdvpa0-36553/2e9ed7b83530569"
                    width="100%"
                    height="700px"
                  ></iframe>
                </TabPanel>
                <TabPanel>
                  <iframe
                    title="Controle de Notas"
                    className="clickup-embed"
                    src="https://sharing.clickup.com/9007126848/l/h/6-901301773647-1/d70265210880ae5"
                    width="100%"
                    height="700px"
                  ></iframe>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}
