import React from "react"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Text,
  Link as LinkChakra,
  Box,
} from "@chakra-ui/react"
import { Link as ReactLink } from "react-router-dom"

import "./SidebarMenuItem.scss"

export default function SidebarMenuItem(props: any) {
  const { title, items } = props
  const level: number = props.level ? parseInt(props.level) : 0

  const printSimpleItem = (
    title: string,
    to: string,
    icon: any,
    level: number
  ) => {
    return (
      <HStack className="parent">
        <LinkChakra
          to={to}
          as={ReactLink}
          w="100%"
          href=""
          gap="1rem"
          display="flex"
          alignItems="center"
          p="0.5rem 1rem"
          className="sidebar"
          transition="ease-in-out 0.2s"
          pb="0.5rem"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Box
            className="icon"
            transition="ease-in-out 0.2s"
            fontSize="20px"
            color="#8E99AB"
          >
            {icon}
          </Box>
          <Text
            className="title"
            transition="ease-in-out 0.2s"
            fontFamily="DM Sans"
            color="#8E99AB"
            fontSize="1rem"
            fontWeight="400"
            lineHeight="1.75rem"
          >
            {title}
          </Text>
        </LinkChakra>
      </HStack>
    )
  }

  return (
    <section className="sidebar-menu-item-container">
      <div>
        {props.items && (
          <>
            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton
                  color="gray.400"
                  _expanded={{}}
                  _hover={{
                    textDecoration: "none",
                  }}
                >
                  <Flex
                    as="span"
                    flex="1"
                    textAlign="left"
                    display="flex"
                    alignItems="center"
                    fontWeight="medium"
                    gap="0.5rem"
                  >
                    {props.icon}
                    {title}
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  {items.map((item: any, index: number) => (
                    <SidebarMenuItem key={index} {...item} level={level + 1} />
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </>
        )}
        {!props.items &&
          printSimpleItem(props.title, props.to, props.icon, level + 1)}
      </div>
    </section>
  )
}
