import Layout from "../../../../components/layout/Layout"
import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import { BsCoin, BsDownload } from "react-icons/bs"
import Loader from "../../../../components/Loader/Loader"
import { useEffect, useState } from "react"
import UserStorage from "../../../../util/userStorage"
import BalanceReturnList from "./BalanceReturnList"
import BalanceReturnListBulk from "./BalanceReturnListBulk"

export default function BalanceReturnListPage() {
  const today = new Date()
  const day = ("0" + today.getDate()).slice(-2)
  const month = ("0" + (today.getMonth() + 1)).slice(-2)

  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState("")
  const [isBulk, setIsBulk] = useState(false)
  const [filters, setFilters] = useState({
    createdAtFrom: today.getFullYear() + "-" + month + "-" + day,
    createdAtTo: "",
    status: "",
    name: "",
    email: "",
    key: "",
  })
  const [bulkFilters, setBulkFilters] = useState({
    createdAtFrom: today.getFullYear() + "-" + month + "-" + day,
    createdAtTo: "",
    status: "",
    name: "",
  })

  const [canApprove, setCanApprove] = useState(false)

  useEffect(() => {
    const roleCode = UserStorage.get()?.role?.code
    if (["admin", "financial"].findIndex((code) => code === roleCode) >= 0) {
      setCanApprove(true)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      {loading && <Loader />}
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="3rem"
      >
        <Flex direction="column">
          <Breadcrumbs
            items={[
              { label: "Dashboard", link: "/" },
              { label: "Solicitações de Resgate", link: "/balance-return" },
            ]}
            additionalHeaderInfo={
              <>
                {/* <Link to="/charge/0"><button><AiOutlinePlus /></button></Link> */}
              </>
            }
          />
          <Flex gap="0.75rem" alignItems="center">
            <Flex fontSize="2rem" fontWeight="700" color="#114B8F">
              <BsCoin />
            </Flex>
            <Text
              fontSize={{
                base: "1.5rem",
                sm: "1.75rem",
                md: "2rem",
                lg: "2.25rem",
              }}
              fontWeight="700"
              color="#114B8F"
              fontFamily="DM Sans"
            >
              Solicitações de Resgate
            </Text>
          </Flex>
        </Flex>
        {isBulk === false && (
          <Flex
            w="auto"
            ml="auto"
            alignSelf="flex-end"
            justifyContent={{
              base: "flex-end",
              sm: "flex-end",
              md: "flex-end",
              lg: "flex-end",
            }}
            gap="1.25rem"
            alignItems="flex-end"
            direction="column"
          >
            <FormControl
              w="100%"
              maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
              isInvalid={false}
              display="flex"
              justifyContent="flex-end"
              gap="0.5rem"
              flexWrap="wrap"
            >
              <Input
                maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Período inicial"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="date"
                name="from"
                value={filters.createdAtFrom}
                onChange={(e) =>
                  setFilters({ ...filters, createdAtFrom: e.target.value })
                }
              />
              <Input
                maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Período final"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="date"
                name="to"
                value={filters.createdAtTo}
                onChange={(e) =>
                  setFilters({ ...filters, createdAtTo: e.target.value })
                }
              />
              <Select
                maxW={{ base: "100%", sm: "120px", md: "120px", lg: "120px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Status"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                value={filters.status}
                onChange={(e) =>
                  setFilters({ ...filters, status: e.target.value })
                }
              >
                <option value="">Todos</option>
                <option value="open">Aberto</option>
                <option value="paid">Pago</option>
              </Select>
              <Input
                maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD", textTransform: "capitalize" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="name"
                placeholder="Nome"
                value={filters.name}
                onChange={(e) =>
                  setFilters({ ...filters, name: e.target.value })
                }
              />
              <Input
                maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD", textTransform: "capitalize" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="key"
                placeholder="Chave"
                value={filters.key}
                onChange={(e) =>
                  setFilters({ ...filters, key: e.target.value })
                }
              />
              <Input
                maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="email"
                name="email"
                placeholder="E-mail"
                value={filters.email}
                onChange={(e) =>
                  setFilters({ ...filters, email: e.target.value })
                }
              />
            </FormControl>
            <Flex gap="1rem">
              <Button
                minW="95px"
                size="md"
                borderRadius="0.375rem"
                bg="#00335B"
                fontFamily="Raleway"
                fontSize="0.875rem"
                fontWeight="600"
                color="#fff"
                _hover={{ bg: "#00335B" }}
                onClick={() => setReload("list")}
              >
                Buscar
              </Button>
              <Button
                minW="95px"
                size="md"
                borderRadius="0.375rem"
                bg="#F7F8F9"
                fontFamily="Raleway"
                fontSize="0.875rem"
                fontWeight="600"
                color="#1A202C"
                _hover={{ bg: "#F7F8F9" }}
                onClick={() => setReload("csv")}
                display="flex"
                gap="0.5rem"
                alignItems="center"
                justifyContent="center"
              >
                <BsDownload />
                CSV
              </Button>
            </Flex>
          </Flex>
        )}

        {isBulk === true && (
          <Flex
            w="100%"
            ml="auto"
            alignSelf="flex-end"
            justifyContent={{
              base: "flex-end",
              sm: "flex-end",
              md: "flex-end",
              lg: "flex-end",
            }}
            gap="1.25rem"
            alignItems="flex-end"
            direction={{ base: "column", sm: "row", md: "row", lg: "row" }}
          >
            <FormControl
              w="100%"
              maxW={{ base: "100%", sm: "100%", md: "100%", lg: "648px" }}
              isInvalid={false}
              display="flex"
              justifyContent="flex-end"
              gap="0.5rem"
              flexWrap="wrap"
            >
              <Input
                maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Período inicial"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="date"
                name="from"
                value={bulkFilters.createdAtFrom}
                onChange={(e) =>
                  setBulkFilters({
                    ...bulkFilters,
                    createdAtFrom: e.target.value,
                  })
                }
              />
              <Input
                maxW={{ base: "100%", sm: "158px", md: "158px", lg: "158px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Período final"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="date"
                name="to"
                value={bulkFilters.createdAtTo}
                onChange={(e) =>
                  setBulkFilters({
                    ...bulkFilters,
                    createdAtTo: e.target.value,
                  })
                }
              />
              <Select
                maxW={{ base: "100%", sm: "120px", md: "120px", lg: "120px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD" }}
                placeholder="Status"
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                value={bulkFilters.status}
                onChange={(e) =>
                  setBulkFilters({ ...bulkFilters, status: e.target.value })
                }
              >
                <option value="">Todos</option>
                <option value="open">Aberto</option>
                <option value="paid">Pago</option>
              </Select>
              <Input
                maxW={{ base: "100%", sm: "204px", md: "204px", lg: "204px" }}
                size="sm"
                fontFamily="Raleway"
                fontSize="0.75rem"
                fontWeight="400"
                _placeholder={{ color: "#A2ACBD", textTransform: "capitalize" }}
                border="1px solid #E2E8F0"
                borderRadius="0.25rem"
                type="text"
                name="name"
                placeholder="Nome"
                value={bulkFilters.name}
                onChange={(e) =>
                  setBulkFilters({ ...bulkFilters, name: e.target.value })
                }
              />
            </FormControl>

            <Flex gap="1rem">
              <Button
                minW="95px"
                size="md"
                borderRadius="0.375rem"
                bg="#00335B"
                fontFamily="Raleway"
                fontSize="0.875rem"
                fontWeight="600"
                color="#fff"
                _hover={{ bg: "#00335B" }}
                onClick={() => setReload("list-bulk")}
              >
                Buscar
              </Button>
              <Button
                minW="95px"
                size="md"
                borderRadius="0.375rem"
                bg="#F7F8F9"
                fontFamily="Raleway"
                fontSize="0.875rem"
                fontWeight="600"
                color="#1A202C"
                _hover={{ bg: "#F7F8F9" }}
                onClick={() => setReload("csv-bulk")}
                display="flex"
                gap="0.5rem"
                alignItems="center"
                justifyContent="center"
              >
                <BsDownload />
                CSV
              </Button>
            </Flex>
          </Flex>
        )}
      </Flex>
      <Tabs
        w="100%"
        onChange={(index) => (index === 0 ? setIsBulk(false) : setIsBulk(true))}
      >
        <TabList>
          <Tab>Solicitações</Tab>
          <Tab>Lotes</Tab>
        </TabList>
        <TabPanels px="0">
          <TabPanel px="0">
            <BalanceReturnList
              loading={loading}
              setLoading={setLoading}
              reload={reload}
              setReload={setReload}
              filters={filters}
              canApprove={canApprove}
            />
          </TabPanel>
          <TabPanel px="0">
            <BalanceReturnListBulk
              loading={loading}
              setLoading={setLoading}
              reload={reload}
              setReload={setReload}
              filters={bulkFilters}
              canApprove={canApprove}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  )
}
