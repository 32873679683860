import React, { useEffect, useState } from "react"
import {
  Link as ReactRouterLink,
  useNavigate,
  useParams,
} from "react-router-dom"
import InputMask from "react-input-mask"
import Layout from "../../../../components/layout/Layout"
import Breadcrumbs from "../../../../components/layout/breadcrumbs/Breadcrumbs"
import {
  Text,
  FormControl,
  FormLabel,
  Input,
  Flex,
  SimpleGrid,
  ButtonGroup,
  Button,
  useToast,
  Select,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { AiOutlineArrowLeft } from "react-icons/ai"

import {
  SearchResultInfo,
  User,
  Vehicle,
  Charge,
  Transaction,
  States,
} from "../../../../entities"
import {
  BankAccountService,
  ChargeService,
  UserService,
  VehicleService,
} from "../../../../services"
import DataTable from "../../../../components/dataTable/DataTable"

import theme from "../../../../config/Themes"
import UserStorage from "../../../../util/userStorage"
import Loader from "../../../../components/Loader/Loader"
import { ValidationInput } from "../../../../util/ValidationInput"
import { GrView } from "react-icons/gr"

export default function CustomerFormPage() {
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState(new User({}))
  const [resultInfo] = useState(new SearchResultInfo())
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [charges, setCharges] = useState<Charge[]>([])
  const [balanceFormated, setBalanceFormated] = useState("")
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const [canEdit, setCanEdit] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : 0

    if (id) {
      load(id)
      loadVehicles(id)
      loadCharges(id)
      loadTransactions(id)
    }

    const roleCode = UserStorage.get()?.role?.code
    if (
      ["admin", "financial", "operational"].findIndex(
        (code) => code === roleCode
      ) >= 0
    ) {
      setCanEdit(true)
      console.log("can-edit")
    }

    // eslint-disable-next-line
  }, [params.id])

  const load = async (id: number) => {
    setIsLoading(true)
    UserService.get(id)
      .then((user) => {
        setForm(user)
      })
      .finally(() => setIsLoading(false))
  }

  const loadVehicles = (userId: number) => {
    VehicleService.getByUser(userId).then((result) => {
      setVehicles(result.data)
    })
  }

  const loadCharges = (userId: number) => {
    ChargeService.getByUser(userId).then((result) => {
      setCharges(result.data)
    })
  }

  const loadTransactions = (userId: number) => {
    BankAccountService.getByUser(userId).then((bankAccount) => {
      console.log("bank-account", bankAccount)
      setBalanceFormated(bankAccount.balanceFormated)
      setTransactions(bankAccount.transactions)
      // setVehicles(result.data)
    })
  }

  const renderType = (params: any) => {
    if (params.type === "add-balance") return "Adicionou saldo"
    if (params.type === "debit") return "Pagamento"
    if (params.type === "redeem") return "Reembolso"
  }

  const renderIsActive = (params: any) => {
    return params.isActive === true ? "Sim" : "Não"
  }

  const vehicleColumns = [
    { field: "licensePlate", headerName: "Placa" },
    { field: "manufacturer", headerName: "Marca" },
    { field: "model", headerName: "Modelo" },
    { field: "color", headerName: "Cor" },
    { renderCell: renderIsActive, headerName: "Ativo?" },
    { field: "createdAtFormated", headerName: "Criação" },
  ]

  const renderChargeActions = (params: any) => {
    return (
      <div>
        <ChakraLink
          as={ReactRouterLink}
          to={`/charge/${params.id}`}
          target="_blank"
        >
          <Button
            bg="#E7ECFF"
            color="#00335B"
            transition="0.2s"
            _hover={{ bg: "#114B8F", color: "#fff" }}
          >
            <GrView />
          </Button>
        </ChakraLink>
      </div>
    )
  }

  const chargeColumns = [
    { field: "datetimeOccurrenceFormated", headerName: "Data de Ocorrência" },
    { field: "licensePlate", headerName: "Placa" },
    { field: "plazaDescription", headerName: "Praça" },
    { field: "laneCode", headerName: "Faixa" },
    { field: "rateAmountFormated", headerName: "Valor" },
    { field: "statusFormated", headerName: "Status" },
    { renderCell: renderChargeActions, headerName: "Ações" },
  ]

  const transactionColumns = [
    { field: "createdAtFormated", headerName: "Data" },
    { renderCell: renderType, headerName: "Tipo" },
    { field: "code", headerName: "Código" },
    { field: "paymentMethodFormated", headerName: "Forma de Pagamento" },
    { field: "amountFormated", headerName: "Valor" },
    { field: "statusFormated", headerName: "Status" },
  ]

  const save = () => {
    const user = new User(form)
    user.roleId = user.role.id

    if (user.firstname.length <= 2) {
      toast({
        title: "Digite o primeiro nome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.lastname.length <= 2) {
      toast({
        title: "Digite o sobrenome.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (user.type === "PF") {
      if (user.document.length < 14) {
        toast({
          title: "Digite o CPF.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCPF(user.document)) {
          toast({
            title: "O CPF informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
    } else {
      if (user.document.length < 18) {
        toast({
          title: "Digite o CNPJ.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCNPJ(user.document)) {
          toast({
            title: "O CNPJ informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
      if (user.companyName.length < 3) {
        toast({
          title: "Digite a Razão Social.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.businessName.length < 3) {
        toast({
          title: "Digite o Nome Fantasia.",
          status: "error",
          duration: 2000,
        })
        return
      }
      if (user.post.length < 3) {
        toast({
          title: "Digite o Cargo.",
          status: "error",
          duration: 2000,
        })
        return
      }
    }

    if (user.postcode.length < 9) {
      toast({
        title: "Digite o CEP.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address1.length < 3) {
      toast({
        title: "Digite o Endereço.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.city.length < 3) {
      toast({
        title: "Digite a Cidade.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.state.length !== 2) {
      toast({
        title: "Escolha o Estado.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.address2.length < 3) {
      toast({
        title: "Digite o Bairro.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.addressNumber.length < 1) {
      toast({
        title: "Digite o Nº.",
        status: "error",
        duration: 2000,
      })
      return
    }
    if (user.phone.length < 15) {
      toast({
        title: "Digite o Telefone",
        status: "error",
        duration: 2000,
      })
      return
    }

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)
    if (!isEmailValid) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    setIsLoading(true)
    UserService.update(user)
      .then((result) => navigate("/customer"))
      .catch((error) => {
        toast({
          title: error?.response.data?.message,
          status: "error",
          duration: 2000,
        })
      })
      .finally(() => setIsLoading(false))
  }

  const remove = () => {
    const resp = window.confirm(
      `Deseja remover o cliente ${form.firstname} ${form.lastname}?`
    )
    if (!resp) return

    UserService.delete(form.id).then((success) => {
      if (!success) {
        alert("Falha ao remover o usuário")
        return
      }

      alert("Usuário removido com sucesso.")

      navigate("/customer")
    })
  }

  return (
    <Layout>
      {isLoading && <Loader />}
      <Breadcrumbs
        items={[
          { label: "Dashboard", link: "/" },
          { label: "Clientes", link: "/customer" },
          { label: "Visualizar", link: `/customer/${form.id}` },
        ]}
        additionalHeaderInfo={
          <>
            <ReactRouterLink to="/customer">
              <button>
                <AiOutlineArrowLeft />
              </button>
            </ReactRouterLink>
          </>
        }
      />
      <Flex
        w="100%"
        h="full"
        p="2rem"
        display="flex"
        flexDirection="column"
        gap="1rem"
      >
        <Text fontSize="2rem" fontWeight="600" color={theme.color.secondary}>
          Cliente
        </Text>
        <SimpleGrid
          alignItems="center"
          columns={{ base: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
          spacing="1rem"
        >
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>E-mail</FormLabel>
            <Input
              type="text"
              name="email"
              value={form.email}
              onChange={(event) =>
                setForm({ ...form, email: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Nome</FormLabel>
            <Input
              type="text"
              name="firstname"
              value={form.firstname}
              onChange={(event) =>
                setForm({ ...form, firstname: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Sobrenome</FormLabel>
            <Input
              type="text"
              name="lastname"
              value={form.lastname}
              onChange={(event) =>
                setForm({ ...form, lastname: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>
              {form.type === "PF" ? "CPF" : "CNPJ"}
            </FormLabel>
            <Input
              type="text"
              name="document"
              value={form.document}
              as={InputMask}
              mask={
                form.type === "PF" ? "999.999.999-99" : "99.999.999/9999-99"
              }
              maskChar={null}
              onChange={(event) =>
                setForm({ ...form, document: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          {form.type === "PF" && (
            <FormControl isRequired>
              <FormLabel>Data de nascimento</FormLabel>
              <Input
                size="lg"
                type="date"
                value={
                  form.birthDate
                    ? form.birthDate.toISOString().split("T")[0]
                    : ""
                }
                onChange={(event) =>
                  setForm({
                    ...form,
                    birthDate: new Date(event.target.value),
                  })
                }
                placeholder="01/01/2000"
              />
            </FormControl>
          )}
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>CEP</FormLabel>
            <Input
              type="text"
              name="postcode"
              value={form.postcode}
              onChange={(event) =>
                setForm({ ...form, postcode: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Endereço</FormLabel>
            <Input
              type="text"
              name="address1"
              value={form.address1}
              onChange={(event) =>
                setForm({ ...form, address1: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Cidade</FormLabel>
            <Input
              type="text"
              name="city"
              value={form.city}
              onChange={(event) =>
                setForm({ ...form, city: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Estado</FormLabel>
            <Select
              size="lg"
              placeholder="Estado"
              disabled={!canEdit}
              value={form.state}
              onChange={(event) =>
                setForm({ ...form, state: event.target.value })
              }
            >
              {States.map((item: any) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Bairro</FormLabel>
            <Input
              type="text"
              name="address2"
              value={form.address2}
              onChange={(event) =>
                setForm({ ...form, address2: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Número</FormLabel>
            <Input
              type="text"
              name="addressNumber"
              value={form.addressNumber}
              onChange={(event) =>
                setForm({ ...form, addressNumber: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Complemento</FormLabel>
            <Input
              type="text"
              name="addressComplement"
              value={form.addressComplement}
              onChange={(event) =>
                setForm({ ...form, addressComplement: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
          <FormControl isInvalid={false}>
            <FormLabel color={theme.color.tertiary}>Telefone</FormLabel>
            <Input
              type="text"
              name="phone"
              value={form.phone}
              onChange={(event) =>
                setForm({ ...form, phone: event.target.value })
              }
              readOnly={!canEdit}
            />
          </FormControl>
        </SimpleGrid>

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Veículos
        </Text>
        <DataTable
          title="Veículos"
          checkboxSelection={false}
          rows={vehicles}
          columns={vehicleColumns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={isLoading}
        />

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Cobranças
        </Text>
        <DataTable
          title="Cobranças"
          checkboxSelection={false}
          rows={charges}
          columns={chargeColumns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={isLoading}
        />

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Saldo: {balanceFormated}
        </Text>

        <Text
          fontSize="1.2rem"
          fontWeight="600"
          color={theme.color.secondary}
          mt="1rem"
        >
          Transações
        </Text>
        <DataTable
          title="Transações"
          checkboxSelection={false}
          rows={transactions}
          columns={transactionColumns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={isLoading}
        />

        <ButtonGroup>
          <Button onClick={save}>Salvar</Button>
          <Button onClick={remove}>Remover</Button>
        </ButtonGroup>
      </Flex>
    </Layout>
  )
}
