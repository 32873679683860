import {
  BsCarFrontFill,
  BsCardHeading,
  BsCardText,
  BsCash,
  BsCoin,
  BsDoorOpenFill,
  BsFillQuestionCircleFill,
  BsFillShieldFill,
  BsHouseDoorFill,
  BsPersonCircle,
  BsPersonFill,
} from "react-icons/bs"

const menuItems = [
  {
    title: "Dashboard",
    to: "/",
    icon: <BsHouseDoorFill />,
  },
  {
    title: "Clientes",
    to: "/customer",
    icon: <BsPersonFill />,
  },
  {
    title: "Cobranças",
    to: "/charge",
    icon: <BsCash />,
  },
  {
    title: "Pagamentos",
    to: "/payment",
    icon: <BsCardHeading />,
  },
  {
    title: "Pagamentos BSO",
    to: "/payment/bso",
    icon: <BsCardText />,
  },
  {
    title: "Transferências",
    to: "/vehicle/transfer",
    icon: <BsCarFrontFill />,
  },
  {
    title: "Resgate de Saldo",
    to: "/balance-return",
    icon: <BsCoin />,
  },
  {
    title: "Usuários",
    to: "/user",
    icon: <BsPersonCircle />,
  },
  {
    title: "Segurança",
    to: "/security",
    icon: <BsFillShieldFill />,
  },
  {
    title: "Ajuda",
    to: "/help",
    icon: <BsFillQuestionCircleFill />,
  },
  {
    title: "Sair",
    to: "/login",
    icon: <BsDoorOpenFill />,
  },
]

export default menuItems
