import { Transaction, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class TransactionRepository {
  public static async search(params: SearchParams, token: string): Promise<SearchResult<Transaction>> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/transaction?page=${params.page}&limit=${params.limit}`, {}, headers)

    const result = new SearchResult<Transaction>(response.data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<Transaction> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest('GET', `/transaction/${id}`, {}, headers)

    return new Transaction(response)
  }

  public static async create(transaction: Transaction, token: string): Promise<Transaction> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('POST', '/transaction', transaction, headers)

    return new Transaction(response)
  }

  public static async update(transaction: Transaction, token: string): Promise<Transaction> {
    const headers = { Authorization: `Bearer ${token}` }
    
    const response = await client.DoRequest('PUT', `/transaction/$[object Object].id}`, transaction, headers)

    return new Transaction(response)
  }

  public static async delete(id: string, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` };

    const response = await client.DoRequest('DELETE', `/transaction/${id}`, {}, headers)

    return response === true
  }
}