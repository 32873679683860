import { User, SearchParams, SearchResult } from "../../entities"
import QueryStringBuilder from "../../util/queryBuilder"
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()

export class UserRepository {
  public static async search(
    params: SearchParams,
    token: string
  ): Promise<SearchResult<User>> {
    const headers = { Authorization: `Bearer ${token}` }

    const queryStringBuilder = new QueryStringBuilder()
    for (const filter of params.filter) {
      queryStringBuilder.addParameter(filter.key, filter.value)
    }
    const queryString = queryStringBuilder.buildQueryString()
    

    const url = `/user?${queryString}&page=${params.page}&limit=${params.limit}`

    const response = await client.DoRequest("GET", url, {}, headers)

    const result = new SearchResult<User>(response.data, response.info)

    return result
  }

  public static async get(id: number, token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }
    const response = await client.DoRequest("GET", `/user/${id}`, {}, headers)

    return new User(response)
  }

  public static async create(user: User, token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest("POST", "/user", user, headers)

    return new User(response)
  }

  public static async update(user: User, token: string): Promise<User> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest(
      "PUT",
      `/user/${user.id}`,
      user,
      headers
    )

    return new User(response)
  }

  public static async delete(id: number, token: string): Promise<boolean> {
    const headers = { Authorization: `Bearer ${token}` }

    const response = await client.DoRequest('DELETE', `/user/${id}`, {}, headers)

    return response === true
  }
}
