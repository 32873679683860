import { Dashboard } from "../../entities/csg/Dashboard"
import { DashboardRepository } from "../../repositories/csg/DashboardRepository"
import UserStorage from "../../util/userStorage"

export class DashboardService {
  public static async get(): Promise<Dashboard> {
    const token = UserStorage.getToken()
    return await DashboardRepository.get(token)
  }
}
